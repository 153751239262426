/* =========================================================== */
/* ================== New Navigation System ================== */
/* =========================================================== */


/* ============================= */
/* =========MAIN MENU=========== */
/* ============================= */

/* Menu Fades */

.wsmenu>.wsmenu-list>li>ul.sub-menu {
  opacity: 0;
  visibility: hidden;
  -o-transform-origin: 0% 0%;
  -ms-transform-origin: 0% 0%;
  -moz-transform-origin: 0% 0%;
  -webkit-transform-origin: 0% 0%;
  -o-transition: -o-transform 0.3s, opacity 0.3s;
  -ms-transition: -ms-transform 0.3s, opacity 0.3s;
  -moz-transition: -moz-transform 0.3s, opacity 0.3s;
  -webkit-transition: -webkit-transform 0.3s, opacity 0.3s;
  transform-style: preserve-3d;
  -o-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  -webkit-transform-style: preserve-3d;
  transform: rotateX(-75deg);
  -o-transform: rotateX(-75deg);
  -moz-transform: rotateX(-75deg);
  -webkit-transform: rotateX(-75deg);
}

.wsmenu>.wsmenu-list>li:hover>ul.sub-menu {
  opacity: 1;
  visibility: visible;
  transform: rotateX(0deg);
  -o-transform: rotateX(0deg);
  -moz-transform: rotateX(0deg);
  -webkit-transform: rotateX(0deg);
}

.wsmenu>.wsmenu-list>li>.wsmegamenu {
  opacity: 0;
  visibility: hidden;
  -o-transform-origin: 0% 0%;
  -ms-transform-origin: 0% 0%;
  -moz-transform-origin: 0% 0%;
  -webkit-transform-origin: 0% 0%;
  -o-transition: -o-transform 0.3s, opacity 0.3s;
  -ms-transition: -ms-transform 0.3s, opacity 0.3s;
  -moz-transition: -moz-transform 0.3s, opacity 0.3s;
  -webkit-transition: -webkit-transform 0.3s, opacity 0.3s;
  transform-style: preserve-3d;
  -o-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  -webkit-transform-style: preserve-3d;
  transform: rotateX(-75deg);
  -o-transform: rotateX(-75deg);
  -moz-transform: rotateX(-75deg);
  -webkit-transform: rotateX(-75deg);
}

.wsmenu>.wsmenu-list>li:hover>.wsmegamenu {
  opacity: 1;
  visibility: visible;
  transform: rotateX(0deg);
  -o-transform: rotateX(0deg);
  -moz-transform: rotateX(0deg);
  -webkit-transform: rotateX(0deg);
}

.wsmenu-list>li>.wsshoptabing {
  opacity: 0;
  visibility: hidden;
  -o-transform-origin: 0% 0%;
  -ms-transform-origin: 0% 0%;
  -moz-transform-origin: 0% 0%;
  -webkit-transform-origin: 0% 0%;
  -o-transition: -o-transform 0.3s, opacity 0.3s;
  -ms-transition: -ms-transform 0.3s, opacity 0.3s;
  -moz-transition: -moz-transform 0.3s, opacity 0.3s;
  -webkit-transition: -webkit-transform 0.3s, opacity 0.3s;
  transform-style: preserve-3d;
  -o-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  -webkit-transform-style: preserve-3d;
  transform: rotateX(-75deg);
  -o-transform: rotateX(-75deg);
  -moz-transform: rotateX(-75deg);
  -webkit-transform: rotateX(-75deg);
}

.wsmenu-list>li:hover .wsshoptabing {
  opacity: 1;
  visibility: visible;
  transform: rotateX(0deg);
  -o-transform: rotateX(0deg);
  -moz-transform: rotateX(0deg);
  -webkit-transform: rotateX(0deg);
}


/* ================== Desktop Base CSS  ================== */
.wsmenu html,
.wsmenu body,
.wsmenu iframe,
.wsmenu h1,
.wsmenu h2,
.wsmenu h3,
.wsmenu h4,
.wsmenu h5,
.wsmenu h6 {
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  vertical-align: baseline;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  /*font-family: Helvetica, sans-serif;
    -webkit-font-smoothing: subpixel-antialiased;
    font-smoothing: antialiased;
    font-smooth: antialiased;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    -webkit-font-smoothing: subpixel-antialiased;
    font-smoothing: subpixel-antialiased;
    font-smooth: subpixel-antialiased;*/
}

.wsmenu .cl {
  clear: both;
}

.wsmenu img,
object,
embed,
video {
  border: 0 none;
  max-width: 100%;
}

.wsmenu a:focus {
  outline: none;
}

.wsmenu:before,
.wsmenu:after {
  content: "";
  display: table;
}

.wsmenu:after {
  clear: both;
}

/* ================== Desktop Main Menu CSS ================== */

.headerfull {
  width: 100%;
  height: 60px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  /*box-shadow: 0 0 4px rgba(0, 0, 0, 0.16);*/
}

.smllogo {
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 60px;
  /* width: 18%; */
}

.smllogo img {
  height: 40px;
}

.wsmobileheader .smllogo img {
  /* max-width: 100%; */

}

.wsmobileheader .smllogo {
  padding: 0;
}

.smllogo a {
  display: block;
  float: left;
  padding: 0;
}

.wsmain {
  width: 100%;
  margin: 0 auto;
  padding: 0 25px;
  max-width: 1161px;
}

.wsmenu {
  width: 81%;
  float: left;
  /*font-family: Helvetica, sans-serif;*/
  color: #fff;
  position: relative;
  font-size: 92%;
  padding: 0;
  display: block;
  margin: 0 0 0 1%;
  // border-left: 1px solid rgba(0, 0, 0, 0.1);
}

.wsmenu>.wsmenu-list {
  text-align: left;
  margin: 0 auto;
  width: 100%;
  display: block;
  padding: 0;
  position: relative;
  max-height: 60px;
}

.wsmenu>.wsmenu-list>li {
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.wsmenu>.wsmenu-list>li>.navtext {
  line-height: 16px;
  text-align: right;
  padding: 14px 20px;
  margin: 0px;
}

.wsmenu>.wsmenu-list>li>a {
  display: block;
  text-decoration: none;
  position: relative;
}

.wsmenu>.wsmenu-list>li>.navtext>span+span:after {
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;

  content: "\f078";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  font-size: 9px;
  line-height: 10px;
  margin-left: 0px;
  position: relative;
  right: -7px;
  text-align: right;
  top: 1px;

}

.wsmenu>.wsmenu-list>li>.navtext>span {
  display: block;
  font-size: 90%;
  text-align: left;
  white-space: nowrap;
  width: 100%;
  line-height: 16px;
}

.wsmenu>.wsmenu-list>li>.navtext>span+span {
  white-space: nowrap;
  font-size: 104%;
  font-weight: bold;
}

.wsmenu>.wsmenu-list>li a:hover .arrow:after {
  border-top-color: #b3b3b3
}

.wsmenu>.wsmenu-list>li a.active .arrow:after {
  border-top-color: #b3b3b3
}

.wsmenu>.wsmenu-list>li:hover>a .arrow:after {
  border-top-color: #b3b3b3
}

/* Main Search Bar CSS*/
.wsmenu>.wsmenu-list>li.wssearchbar {
  width: 33%;
  float: left;
  padding-left: 20px;
}

/* Reduce Search Bar width on MD screen*/
@include media-breakpoint-down(lg) {
  .wsmenu>.wsmenu-list>li.wssearchbar {
    width: 33%;
  }
}

.wsmenu>.wsmenu-list>li.wssearchbar>.topmenusearch {
  float: right;
  width: 100%;
  height: 42px;
  position: relative;
  margin: 0;
}

.wsmenu>.wsmenu-list>li.wssearchbar>.topmenusearch>.searchicon {
  -webkit-transition: all 0.7s ease 0s;
  -moz-transition: all 0.7s ease 0s;
  -o-transition: all 0.7s ease 0s;
  transition: all 0.7s ease 0s;
}

.wsmenu>.wsmenu-list>li.wssearchbar>.topmenusearch>input {
  width: 100%;
  position: relative;
  float: right;
  top: 0;
  right: 0;
  bottom: 0;
  border: 0;
  padding: 0;
  margin: 0;
  text-indent: 15px;
  font-size: 100%;
  height: 42px;
  z-index: 2;
  outline: none;
  color: #7d7d7d;
  transition: all 0.7s ease 0s;
  border-radius: $border-radius;
}

.wsmenu>.wsmenu-list>li.wssearchbar>.topmenusearch>input:focus~.btnstyle {
  color: #fff;
  opacity: 0.9;
}

.wsmenu>.wsmenu-list>li.wssearchbar>.topmenusearch>input:focus~.searchicon {
  opacity: 1;
  z-index: 3;
  color: #FFFFFF;
}

.wsmenu>.wsmenu-list>li.wssearchbar>.topmenusearch>.btnstyle {
  top: 0px;
  position: absolute;
  right: 0;
  bottom: 0;
  width: 49px;
  line-height: 30px;
  z-index: 1;
  cursor: pointer;
  color: #fff;
  z-index: 1000;
  border: solid 0px;
  transition: all 0.7s ease 0s;
  border-radius: 0px $border-radius $border-radius 0px;
}

.wsmenu>.wsmenu-list>li.wssearchbar>.topmenusearch>.btnstyle>i {
  line-height: 38px;
  margin: 0;
  padding: 0;
  text-align: center;
  color: #9c9c9c;
}

/* My Account Dropdown */
.wsmenu>.wsmenu-list>li>ul.sub-menu {
  position: absolute;
  top: 59px;
  z-index: 1000;
  margin: 0;
  padding: 0;
  min-width: 160px;
  background-color: #fff;
  border: solid 1px #eeeeee;
}

.wsmenu>.wsmenu-list>li>ul.sub-menu>li {
  position: relative;
  margin: 0;
  padding: 0;
  display: block;
}

.wsmenu>.wsmenu-list>li>ul.sub-menu>li>a {
  background-image: none;
  color: #666666;
  border-right: 0 none;
  text-align: left;
  display: block;
  line-height: 24px;
  padding: 8px 12px;
  text-transform: none;
  font-size: 92%;
  letter-spacing: normal;
  border-right: 0px solid;
}

.wsmenu>.wsmenu-list>li>ul.sub-menu>li>a:hover {
  text-decoration: none;
}

.wsmenu>.wsmenu-list>li>ul.sub-menu>li>a>i {
  margin-right: 9px;
  font-size: 14px;
}



.wsmenu>.wsmenu-list>li.wsshopmyaccount {
  float: right;
}

.wsmenu>.wsmenu-list>li.wsshopmyaccount>a {
  display: block;
  padding: 0 14px 0 14px;
  line-height: 57px;
  text-decoration: none;
  position: relative;
}



.wsmenu>.wsmenu-list>.wsshopmyaccount>a i {
  display: inline-block;
  font-size: 13px;
  line-height: inherit;
  margin-right: 11px;
  /*vertical-align: middle;*/
}


/* Main Menu Flag Icon*/
.wsmenu>.wsmenu-list>li.wsflagicon {
  float: right;
  clear: right;
}

.wsmenu>.wsmenu-list>li.wsflagicon>a {
  display: block;
  padding: 0 14px 0 14px;
  line-height: 57px;
  text-decoration: none;
  position: relative;
}

.wsmenu>.wsmenu-list>.wsflagicon>a i {
  display: inline-block;
  font-size: 13px;
  line-height: inherit;
  margin-right: 11px;
  vertical-align: middle;
}

li.wsflagicon .sub-menu {
  min-width: 100px !important;
}


/* Main Menu Cart Icon*/
.wsmenu>.wsmenu-list>li.wscarticon {
  float: right;
}

.wsmenu>.wsmenu-list>li.wscarticon>a {
  display: block;
  color: #828181;
  padding: 0 26px 0 22px;
  line-height: 57px;
  text-decoration: none;
  position: relative;
}

.wsmenu>.wsmenu-list>li.wscarticon .hidetxt {
  display: none;
}

.wsmenu>.wsmenu-list>li.wscarticon a {
  padding: 0 17px 0 13px;
  text-align: center;
  /* border-left: 1px solid rgba(0, 0, 0, 0.1);
    border-right: 1px solid rgba(0, 0, 0, 0.1);*/
}

.wsmenu>.wsmenu-list>li.wscarticon a i {
  font-size: 16px;
}

.wsmenu>.wsmenu-list>li.wscarticon em.roundpoint {
  position: absolute;
  top: 14px;
  right: 11px;
  width: 17px;
  height: 17px;
  background-color: theme-color("primary");
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  font-size: 10px;
  text-align: center;
  font-style: normal;
  line-height: 16px;
  color: #fff;
}

.wsmenu>.wsmenu-list>li.wscarticon i {
  display: inline-block;
  font-size: 13px;
  line-height: inherit;
  margin-right: 2px;
  color: #7b7b7b;
}

/* ================== Desktop Tabing Mega Menus CSS  ================== */
/* Tabing Leftside */
.wsmenu>.wsmenu-list>li>.wsshoptabing {
  width: 100%;
  text-align: left;
  margin: 0px;
  padding: 0px;
  position: absolute;
  top: 60px;
  background-color: #f8f8f8;
  left: 0;
  border-bottom: solid 2px #376cb7;
  border-top: solid 0px;
  z-index: 101;
}

.wsmenu>.wsmenu-list>li>.wsshoptabing>.wsshopwp {
  width: 100%;
  display: block;
  position: relative;
  background-color: #f5f5f5;
}

.wsmenu>.wsmenu-list>li>.wsshoptabing.wtsdepartmentmenu {
  background: #fff;
  border-bottom: solid 1px #d1d3d4;
}

.wsmenu>.wsmenu-list>li>.wsshoptabing>.wsshopwp>.wstabitem {
  display: block;
  margin: 0px;
  padding: 0px;
  font-size: 12px;
  background-color: #f5f5f5;
}

.wsmenu>.wsmenu-list>li>.wsshoptabing>.wsshopwp>.wstabitem>li:before,
.wsmenu>.wsmenu-list>li>.wsshoptabing>.wsshopwp>.wstabitem>li:after {
  content: "";
  display: table;
}

.wsmenu>.wsmenu-list>li>.wsshoptabing>.wsshopwp>.wstabitem>li:after {
  clear: both;
}

.wsmenu>.wsmenu-list>li>.wsshoptabing>.wsshopwp>.wstabitem>li {
  width: 100%;
  list-style: none;
}

.wsmenu>.wsmenu-list>li>.wsshoptabing>.wsshopwp>.wstabitem>li>a {
  display: block;
  padding: 15px 11px;
  font-size: 120%;
  color: #606060;
  border-bottom: solid 1px #e5e5e5;
  width: 23%;
  float: left;
  position: relative;
  border-left: 3px solid #fff;
  background-color: #fff;
}

.wsmenu>.wsmenu-list>li>.wsshoptabing>.wsshopwp>.wstabitem>li:hover a {
  text-decoration: none;
}

.wsmenu>.wsmenu-list>li>.wsshoptabing>.wsshopwp>.wstabitem>li:hover>a {
  text-decoration: none;
}

.wsmenu>.wsmenu-list>li>.wsshoptabing>.wsshopwp>.wstabitem>li>a>i {
  color: #848484;
  margin-right: 5px;
  text-align: center;
  width: 24px;
  font-size: 16px;
}

/* Tabing Rightside */
.wsmenu>.wsmenu-list>li>.wsshoptabing>.wsshopwp>.wstabitem>li>.wstitemright {
  opacity: 0;
  visibility: hidden;
  position: absolute;
  right: 0;
  top: 0;
  background-color: #f5f5f5;
  color: #000;
  //display: block;
  float: left;
  padding: 10px 8px 0px 8px;
  width: 77%;
  min-height: 100%;
}

.wsmenu>.wsmenu-list>li:hover>.wsshoptabing>.wsshopwp>.wstabitem>li.wsshoplink-active>.wstitemright {
  opacity: 1;
  visibility: visible;
}

/* Mega Menu Content Formatting */
.carousel-caption {
  width: 100%;
  display: block;
  text-align: center;
  right: 0;
  left: 0;
  margin: 0;
  padding: 0;
  bottom: 0;
}

.carousel-caption h3 {
  width: 100%;
  display: block;
  padding: 7px 0px;
  background-color: rgba(0, 0, 0, 0.5);
}

.wsmenu>.wsmenu-list>li .wstbootslider {
  float: right;
  margin-top: 8px;
}

.wsmenu>.wsmenu-list>li .wstmegamenucolr {
  display: block;
  float: right;
  margin-top: 8px;
}

.wsmenu>.wsmenu-list>li .wstheading {
  width: 100%;
  display: block;
  padding: 10px 0px 6px 0px;
  font-size: 112%;
  font-weight: bold;
  color: #333333;
  border-bottom: solid 1px #d9d9d9;
  margin-bottom: 12px;
}

.wsmenu>.wsmenu-list>li .wstliststy01 {
  padding: 0px 0px 8px 0px;
  margin: 0px;
}

.wsmenu>.wsmenu-list>li .wstliststy01 li {
  width: 33.33%;
  float: left;
  line-height: 23px;
  font-size: 14px;
  list-style: none;
  padding: 0px 0px 0px 0px;
  margin: 0px;
}

.wsmenu>.wsmenu-list>li .wstliststy01 li a {
  color: #6e6e6e;
  font-size: 12px;
}

.wsmenu>.wsmenu-list>li .wstliststy01 li a:hover {
  color: #000;
  text-decoration: none;
}

.wsmenu>.wsmenu-list>li .wstliststy02 {
  padding: 5px 0px 10px 0px;
  margin: 0px;
  text-align: left;
}

.wsmenu>.wsmenu-list>li .wstliststy02 li {
  width: 100%;
  list-style: none;
  line-height: 25px;
}

.wsmenu>.wsmenu-list>li .wstliststy02 li a {
  color: #6e6e6e;
  font-size: 112%;
}

.wsmenu>.wsmenu-list>li .wstliststy02 li a:hover {
  color: #000;
  text-decoration: none;
}

.wsmenu>.wsmenu-list>li .wstliststy02 li.wstheading {
  line-height: normal;
  padding-left: 0px;
  margin-top: 0px;
  text-transform: none;
}

.wsmenu>.wsmenu-list>li .wstliststy02 li a .wstcount {
  font-size: 10px;
  color: #adadad;
}

.wsmenu>.wsmenu-list>li .wstliststy06 li.wstheading {
  line-height: normal;
  padding-left: 0px;
}

.wsmenu>.wsmenu-list>li .wstliststy06 {
  padding: 0px 0px 0px 0px;
  margin: 0px;
}

.wsmenu>.wsmenu-list>li .wstliststy06 li {
  width: 100%;
  list-style: none;
  line-height: 24px;
}

.wsmenu>.wsmenu-list>li .wstliststy06 li a {
  color: #6e6e6e;
  font-size: 12px;
}

.wsmenu>.wsmenu-list>li .wstliststy06 li a:hover {
  color: #000;
  text-decoration: none;
}

.wsmenu>.wsmenu-list>li .wstliststy06 li.wstheading {
  line-height: normal;
}

.wsmenu>.wsmenu-list>li .wstliststy06 li a .wstcount {
  font-size: 10px;
  color: #adadad;
}

.wsmenu>.wsmenu-list>li .wstliststy03 {
  width: 100%;
  padding: 0px 0px 8px 0px;
  margin: 0px;
}

.wsmenu>.wsmenu-list>li .wstliststy03 li {
  width: 33.33%;
  float: left;
  line-height: 23px;
  font-size: 14px;
  list-style: none;
  padding: 0px 0px 0px 0px;
  margin: 0px;
}

.wsmenu>.wsmenu-list>li .wstliststy03 li a {
  color: #6e6e6e;
  font-size: 12px;
}

.wsmenu>.wsmenu-list>li .wstliststy03 li a:hover {
  color: #000;
  text-decoration: none;
}

.wsmenu>.wsmenu-list>li .wstliststy04 {
  padding: 14px 0px 0px 0px;
  margin: 0px;
}

.wsmenu>.wsmenu-list>li .wstliststy04 li {
  width: 100%;
  list-style: none;
  line-height: 22px;
}

.wsmenu>.wsmenu-list>li .wstliststy04 li a {
  color: #6e6e6e;
  font-size: 12px;
}

.wsmenu>.wsmenu-list>li .wstliststy04 li a:hover {
  color: #000;
  text-decoration: none;
}

.wsmenu>.wsmenu-list>li .wstliststy04 li.wstheading {
  line-height: normal;
  text-align: center;
  padding: 12px 0px;
  font-size: 13px;
  margin-bottom: 12px;
}

.wsmenu>.wsmenu-list>li .wstliststy04 li a .wstcount {
  font-size: 10px;
  color: #adadad;
}

.wsmenu>.wsmenu-list>li .wstliststy05 {
  padding: 10px 0px 0px 0px;
  margin: 0px;
}

.wsmenu>.wsmenu-list>li .wstmegamenucolr03 {
  padding-top: 10px;
  padding-left: 20px;
}

.wsmenu>.wsmenu-list>li .wstliststy05 li {
  width: 100%;
  list-style: none;
  line-height: 22px;
}

.wsmenu>.wsmenu-list>li .wstliststy05 li a {
  color: #6e6e6e;
  font-size: 12px;
}

.wsmenu>.wsmenu-list>li .wstliststy05 li a:hover {
  color: #000;
  text-decoration: none;
}

.wsmenu>.wsmenu-list>li .wstliststy05 li.wstheading {
  line-height: normal;
}

.wsmenu>.wsmenu-list>li .wstliststy05 li a .wstcount {
  font-size: 10px;
  color: #adadad;
}

.wsmenu>.wsmenu-list>li .wstmorebtn {
  border-radius: 2px;
  color: #9b9b9b;
  display: inline-block;
  float: right;
  font-size: 10px;
  font-weight: normal;
  letter-spacing: 0;
  padding: 1px 7px;
  text-align: right;
  text-transform: none;
}

.wsmenu>.wsmenu-list>li .wstmenutag {
  height: 18px;
  line-height: 18px;
  text-align: center;
  font-size: 11px;
  color: #fff;
  border-radius: 2px;
  position: relative;
  /*font-family: Arial, Helvetica, sans-serif;*/
  font-weight: normal;
  padding: 1px 6px 1px 6px;
  margin-left: 6px;
  text-transform: none;
  letter-spacing: -0.0px;
}

.wsmenu>.wsmenu-list>li .wstmenutag:after {
  -moz-border-bottom-colors: none;
  -moz-border-left-colors: none;
  -moz-border-right-colors: none;
  -moz-border-top-colors: none;
  border-image: none;
  border-style: solid;
  border-width: 3px;
  content: "";
  left: -6px;
  margin-top: 0px;
  position: absolute;
  top: 4px;
  z-index: 1;
}

.wsmenu>.wsmenu-list>li .wstmenutag.redtag {
  background-color: #fe7b8f;
}

.wsmenu>.wsmenu-list>li .wstmenutag.redtag:after {
  border-color: transparent #fe7b8f transparent transparent;
}

.wsmenu>.wsmenu-list>li .wstmenutag.greentag {
  background-color: #00c853;
}

.wsmenu>.wsmenu-list>li .wstmenutag.greentag:after {
  border-color: transparent #00c853 transparent transparent;
}

.wsmenu>.wsmenu-list>li .wstmenutag.bluetag {
  background-color: #4fc3f7;
}

.wsmenu>.wsmenu-list>li .wstmenutag.bluetag:after {
  border-color: transparent #4fc3f7 transparent transparent;
}

.wsmenu>.wsmenu-list>li .wstmenutag.orangetag {
  background-color: #FE7000;
}

.wsmenu>.wsmenu-list>li .wstmenutag.orangetag:after {
  border-color: transparent #FE7000 transparent transparent;
}

.wsmenu>.wsmenu-list>li .kitchenmenuimg {
  //background-image: url(../images/kitchen-menu-img.png);
  background-position: bottom right;
  background-repeat: no-repeat;
}

.wsmenu>.wsmenu-list>li .computermenubg {
  //background-image: url(../images/computer-menu-img.png);
  background-position: bottom right;
  background-repeat: no-repeat;
}

.wsmenu>.wsmenu-list>li .wstpngsml {
  padding-left: 18px;
}

.wsmenu>.wsmenu-list>li .wstfullwtag {
  width: 100%;
  display: block;
  border-bottom: solid 1px #e5e5e5;
  background-color: #f5f5f5;
}

/* Brand Mega Menu */
.wsmenu>.wsmenu-list>li .wstbrandbottom {
  width: 100%;
  background-color: #fff;
  display: block;
  opacity: 0;
  position: absolute;
  right: 0;
  visibility: hidden;
  padding: 9px 6px;
}

.wsmenu-list>li:hover>.wtsbrandmenu>.wsshoptabingwp>.wstabitem02>li.wsshoplink-active>.wstbrandbottom {
  opacity: 1;
  visibility: visible;
}

.wsmenu>.wsmenu-list>li .wstabitem02 {
  width: 100%;
  padding: 0px;
  margin: 0px 0px;
  list-style: none;
  display: table;
}

.wsmenu>.wsmenu-list>li .wstabitem02>li {
  display: table-cell;
  list-style: outside none none;
  text-align: center;
}

.wsmenu>.wsmenu-list>li .wstabitem02>li>a {
  display: block;
  padding: 14px 0px;
  font-size: 13px;
  color: #717171;
  background-color: #f5f5f5;
  border-bottom: 1px solid #e5e5e5;
  position: relative;
}

.wsmenu>.wsmenu-list>li .wstabitem02>.wsshoplink-active>a:after {
  position: absolute;
  content: '';
  top: 42px;
  right: 50%;
  width: 13px;
  height: 13px;
  transform: rotate(225deg);
  -webkit-transform: rotate(225deg);
  -moz-transform: rotate(225deg);
  -o-transform: rotate(225deg);
  -ms-transform: rotate(225deg);
  border-right: 1px solid #dbdbdb;
  border-bottom: 1px solid #dbdbdb;
  z-index: 100;
  background-color: #ffffff;
}

.wsmenu>.wsmenu-list>li .wstabitem02>li:hover a {
  text-decoration: none;
  -webkit-border-radius: 4px 0px 0px 4px;
  -moz-border-radius: 4px 0px 0px 4px;
  border-radius: 4px 0px 0px 4px;
}

.wsmenu>.wsmenu-list>li .wstabitem02>.wsshoplink-active>a {
  text-decoration: none;
  -webkit-border-radius: 4px 0px 0px 4px;
  -moz-border-radius: 4px 0px 0px 4px;
  border-radius: 4px 0px 0px 4px;
  background-color: #f5f5f5;
  color: #333;
}

.wsmenu>.wsmenu-list>li .wstabitem02>.wsshoplink-active>a>i {
  opacity: 1;
}

.wsmenu>.wsmenu-list>li .wstabitem02>li:hover a {
  text-decoration: none;
  -webkit-border-radius: 4px 0px 0px 4px;
  -moz-border-radius: 4px 0px 0px 4px;
  border-radius: 4px 0px 0px 4px;
}

.wsmenu>.wsmenu-list>li .wstabitem02>li>a i {
  margin-right: 5px;
  text-align: center;
  width: 25px;
  font-size: 17px;
  opacity: 0.5;
}

.wsmenu>.wsmenu-list>li .brandcolor01 {
  color: #424242;
}

.wsmenu>.wsmenu-list>li .brandcolor02 {
  color: #00bcf2;
}

.wsmenu>.wsmenu-list>li .brandcolor03 {
  color: #00aff0;
}

.wsmenu>.wsmenu-list>li .brandcolor04 {
  color: #003087;
}

.wsmenu>.wsmenu-list>li .brandcolor05 {
  color: #a82400;
}

.wsmenu>.wsmenu-list>li .brandcolor06 {
  color: #ff3300;
}

.wsmenu>.wsmenu-list>li .brandcolor07 {
  color: #7ac142;
}

.wsmenu>.wsmenu-list>li .brandcolor08 {
  color: #ef4056;
}

/* ================== Desktop Mega Menus CSS  ================== */
.wsmenu>.wsmenu-list>li>.wsmegamenu {
  width: 100%;
  left: 0px;
  position: absolute;
  top: 60px;
  color: #000;
  z-index: 1000;
  margin: 0px;
  text-align: left;
  padding: 14px 5px;
  font-size: 15px;
  border: solid 1px #eeeeee;
  background-color: #fff;
}

.wsmenu>.wsmenu-list>li>.wsmegamenu .title {
  border-bottom: 1px solid #CCC;
  font-size: 14px;
  padding: 9px 5px 9px 0px;
  font-size: 17px;
  color: #424242;
  margin: 0px 0px 7px 0px;
  text-align: left;
  height: 39px;
}

.wsmenu>.wsmenu-list>li>.wsmegamenu .link-list li {
  display: block;
  text-align: center;
  white-space: nowrap;
  text-align: left;
}

.wsmenu>.wsmenu-list>li>.wsmegamenu .link-list li a {
  line-height: 18px;
  border-right: none;
  text-align: left;
  padding: 6px 0px;
  background: #fff;
  background-image: none;
  color: #666666;
  border-right: 0 none;
  display: block;
  background-color: #fff;
  color: #424242;
}

.wsmenu>.wsmenu-list>li>.wsmegamenu li i {
  margin-right: 5px;
  text-align: center;
  width: 18px;
}

.wsmenu>.wsmenu-list>li>.wsmegamenu li a:hover {
  background: transparent;
  text-decoration: underline;
}

.wsmenu>.wsmenu-list>li>.wsmegamenu .link-list li i {
  font-size: 11px;
}

.wsmenu>.wsmenu-list>li>.wsmegamenu li i {
  margin-right: 5px;
  text-align: center;
  width: 18px;
}

.wsmenu>.wsmenu-list>li>.wsmegamenu .mrgtop {
  margin-top: 15px;
}

.wsmenu>.wsmenu-list>li>.wsmegamenu .show-grid div {
  padding-bottom: 10px;
  padding-top: 10px;
  background-color: #dbdbdb;
  border: 1px solid #e7e7e7;
  color: #6a6a6a;
  margin: 2px 0px;
  font-size: 13px;
}

/* ================== Desktop Half Menus CSS  ================== */
.wsmenu>.wsmenu-list>li>.wsmegamenu.halfmenu {
  width: 40%;
  right: auto;
  left: auto;
}

.wsmenu>.wsmenu-list>li>.wsmegamenu.halfdiv {
  width: 35%;
  right: 0px;
  left: auto;
}

/* ================== Desktop Extra CSS ================== */
.wsmobileheader {
  display: none;
}

.overlapblackbg {
  opacity: 0;
  visibility: hidden;
}

.wsmenu .wsmenu-click {
  display: none;
}

.wsmenu .wsmenu-click02 {
  display: none;
}

.wsmenu .wsmenu-click03 {
  display: none;
}

.hometext {
  display: none;
}


/*==============================================================================
                              Start Mobile CSS
===============================================================================*/
/* ================== Mobile Menu Change Brake Point ================== */
@media only screen and (max-width: 991px) {

  /* ================== Mobile Base CSS ================== */
  html {
    height: 100%;
    -webkit-overflow-scrolling: touch;
  }

  body {
    overflow-y: auto;
    overflow-x: hidden;
  }

  body.wsactive {
    overflow: hidden;
  }

  /* ================== Mobile Main Menu CSS ================== */
  .smllogo {
    display: none;
  }

  .smllogo a {
    float: none;
  }

  .smllogo img {
    height: 38px;
  }

  .wsmain {
    margin: 0px;
    background-color: transparent;
  }

  .wsmenu {
    width: 100%;
    background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
    left: 0;
    overflow-y: hidden;
    padding: 0;
    top: 0;
    visibility: hidden;
    position: fixed;
    margin: 0px;
    border-left: none;
  }

  .wsmenu>.wsmenu-list {
    height: auto;
    min-height: 100%;
    width: 260px;
    background: #fff;
    padding-bottom: 0;
    margin-left: -260px;
    display: block;
    text-align: center;
    -webkit-transition: all 0.25s ease-in-out;
    -moz-transition: all 0.25s ease-in-out;
    -o-transition: all 0.25s ease-in-out;
    -ms-transition: all 0.25s ease-in-out;
    transition: all 0.25s ease-in-out;
    position: static;
  }

  .wsmenu>.wsmenu-list>li {
    width: 100%;
    display: block;
    float: none;
    border-right: none;
    background-color: #fff;
    position: relative;
    white-space: inherit;
  }

  @supports (-webkit-overflow-scrolling: touch) {
    .wsmenu>.wsmenu-list>li:last-child {
      padding-bottom: 110px;
    }
  }

  .wsmenu>.wsmenu-list>li>a {
    padding: 9px 32px 9px 18px;
    font-size: 102%;
    text-align: left;
    border-right: solid 0px;
    background-color: transparent;
    color: #666666;
    line-height: 25px;
    border-bottom: 1px solid;
    position: static;
  }


  .wsmenu>.wsmenu-list>li .wstliststy02 li {
    padding-right: 40px;
  }

  .wsmenu>.wsmenu-list>li .wstliststy02 li.wstheading {
    padding-right: 50px;
  }

  .wsmenu>.wsmenu-list>li>.navtext>span+span:after {
    display: none !important;
  }

  .wsmenu>.wsmenu-list>li>.navtext {
    padding-left: 18px;
  }

  .wsmenu>.wsmenu-list>li.wscarticon a {
    padding-left: 18px;
  }

  .wsmenu>.wsmenu-list>li.wsshopmyaccount>a {
    padding-left: 18px;
  }

  .wsmenu>.wsmenu-list>li.wsflagicon>a {
    padding-left: 18px;
  }

  .wsmenu>.wsmenu-list>li>a>i {
    font-size: 16px;
    color: #bfbfbf;
  }

  .wsmenu>.wsmenu-list>li.wscarticon a i {
    margin-right: 7px;
    font-size: 15px;
  }

  .wsmenu>.wsmenu-list>li>a .wsarrow:after {
    display: none;
  }

  .wsmenu>.wsmenu-list>li.wscarticon em.roundpoint {
    display: inline-block;
    right: auto;
    left: 27px;
  }

  .wsmenu>.wsmenu-list>li:hover>a {
    background-color: rgba(0, 0, 0, 0.08);
    text-decoration: none;
  }

  .wsmenu>.wsmenu-list>li>a>.hometext {
    display: inline-block;
  }

  .wsmenu>.wsmenu-list>li.wscarticon .hidetxt {
    display: inline-block;
  }

  .wsmenu>.wsmenu-list>li.wssearchbar {
    display: none;
  }


  .wsmenu>.wsmenu-list>li.wscarticon {
    float: none;
  }

  .wsmenu>.wsmenu-list>li.wsshopmyaccount {
    float: none;
  }

  .wsmenu>.wsmenu-list>li.wsflagicon {
    float: none;
  }

  .wsmenu>.wsmenu-list>li.wscarticon a {
    text-align: left;
    font-size: 102%;
  }

  /* ================== Mobile Dropdown CSS ================== */
  .wsmenu>.wsmenu-list>li>ul.sub-menu {
    //display: none;
    position: relative;
    top: 0px;
    background-color: #fff;
    border-bottom: solid 1px #ccc;
    padding: 0px;
    opacity: 1;
    visibility: visible;
    -webkit-transform: none;
    -moz-transform: none;
    -ms-transform: none;
    -o-transform: none;
    transform: none;
    -webkit-transition: inherit;
    -moz-transition: inherit;
    transition: inherit;
    -webkit-transform-style: flat;
  }

  .wsmenu>.wsmenu-list>li>ul.sub-menu>li>a {
    line-height: 20px;
    font-size: 90%;
    padding: 13px 0px 13px 16px;
    color: #6e6e6e;
    border-bottom: solid 1px rgba(0, 0, 0, 0.13);
  }

  .wsmenu>.wsmenu-list>li>ul.sub-menu>li span+a {
    padding-right: 30px;
  }

  .wsmenu>.wsmenu-list>li>ul.sub-menu>li>a:hover {
    background-color: #e7e7e7;
    color: #666666;
    text-decoration: underline;
  }

  .wsmenu>.wsmenu-list>li>ul.sub-menu li:hover>a {
    background-color: #e7e7e7;
    color: #666666;
  }

  /* ================== Mobile Mega Menu CSS  ================== */
  .wsmenu>.wsmenu-list>li>.wsmegamenu {
    background-color: #fff;
    padding-top: 5px;
    color: #666666;
    display: none;
    position: relative;
    top: 0px;
    padding: 8px 0px 8px 0px;
    border: solid 0px;
    opacity: 1;
    visibility: visible;
    transform: none;
    -o-transform: none;
    -moz-transform: none;
    -webkit-transform: none;
    border-bottom: 1px solid rgba(0, 0, 0, 0.13);
    -webkit-transform-style: flat;
  }

  .wsmenu>.wsmenu-list>li>.wsmegamenu.halfmenu {
    width: 100%;
  }

  .wstabitem02>.wsshoplink-active>a:after {
    display: none;
  }

  .wsmenu>.wsmenu-list>li>.wsshoptabing>.wsshopwp>.wstabitem>li {
    position: relative;
  }

  .wsmenu>.wsmenu-list>li>.wsshoptabing>.wsshopwp>.wstabitem>li>a {
    width: 100%;
    margin: 0px;
    float: none;
    font-size: 112%;
    padding: 14px 50px 14px 11px;
  }

  .wsmenu>.wsmenu-list>li .wstheading {
    font-weight: normal;
    padding-left: 0px;
    padding-right: 0px;
  }

  .wsmenu>.wsmenu-list>li>.wsshoptabing>.wsshopwp>.wstabitem>li>.wstitemright {
    width: 100%;
    position: static;
    top: 0px;
    min-height: inherit;
    padding: 10px 0px;
    opacity: 1;
    visibility: visible;
    //display: none;
    background-color: #fff;
    border-bottom: 1px solid rgba(0, 0, 0, 0.13);
  }

  .wsmenu>.wsmenu-list>li .wstbootslider {
    width: auto;
  }

  .wsmenu>.wsmenu-list>li .wstmegamenucolr {
    width: auto;
  }

  .wsmenu>.wsmenu-list>li .wstliststy01>li {
    width: 100%;
    padding: 0px;
    margin: 0px;
  }

  .wsmenu>.wsmenu-list>li .wstliststy02 {
    width: 100%;
    padding: 0px;
    margin: 0px 0px 15px 0px;
  }

  .wsmenu>.wsmenu-list>li .wstliststy03 li {
    width: 100%;
  }

  .wsmenu>.wsmenu-list>li .wstliststy05 {
    width: 100%;
    padding: 0px;
    margin-bottom: 10px;
  }

  .wsmenu>.wsmenu-list>li .kitchenmenuimg {
    background-image: none;
  }

  .wsmenu>.wsmenu-list>li .computermenubg {
    background-image: none;
  }

  .wsmenu>.wsmenu-list>li .wstbrandbottom {
    width: 100%;
    position: static;
    top: 0px;
    min-height: inherit;
    padding: 10px 5px 15px 5px;
    opacity: 1;
    visibility: visible;
    display: none;
    border-bottom: 1px solid rgba(0, 0, 0, 0.13);
  }

  .wsmenu>.wsmenu-list>li .wstabitem02 {
    display: block;
  }

  .wsmenu>.wsmenu-list>li .wstabitem02>li {
    position: relative;
    text-align: left;
    display: block;
  }

  .wsmenu>.wsmenu-list>li .wstabitem02>li>a {
    padding: 13px 11px;
  }

  .wsmenu>.wsmenu-list>li .wstabitem02>li>a {
    background-color: #fff;
  }

  .wsmenu>.wsmenu-list>li .wstheading {
    padding-left: 0px;
  }

  .wsmenu-list>li>.navtext {
    margin: 0px;
  }

  .wsmenu .wsmenu-list>li .wsshoptabingwp {
    background-color: #fff;
    padding-left: 0px;
    padding-right: 0px;
  }

  .wsmenu>.wsmenu-list>li>.wsshoptabing {
    background-color: #ffffff;
    color: #666666;
    //display: none;
    position: relative;
    top: 0px;
    padding: 0px;
    border: solid 0px;
    opacity: 1;
    visibility: visible;
    transform: none;
    -o-transform: none;
    -moz-transform: none;
    -webkit-transform: none;
    -webkit-transform-style: flat;
  }

  .wsmenu>.wsmenu-list>li>.wsshoptabing>.wsshopwp>.wstabitem {
    width: 100%;
    background-color: #fff;
  }

  .wsmenu>.wsmenu-list>li>.wsshoptabing>.wsshopwp>.wstabitem>li>a:after {
    border: none;
  }

  .wsmenu>.wsmenu-list>li>.wsshoptabing>.wsshopwp>.wstabitem>li>a i {
    display: none;
  }

  /* ================== Mobile Header CSS ================== */
  .wsmobileheader {
    width: 100%;
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1010;
    height: 60px;
    background-color: #eaecf0;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    transition: all 0.25s ease-in-out;
    /*box-shadow: 0 0 1px rgba(0, 0, 0, .3);*/
  }

  .wsactive .wsmobileheader {
    margin-left: 260px;
    -webkit-transition: all 0.25s ease-in-out;
    -moz-transition: all 0.25s ease-in-out;
    -o-transition: all 0.25s ease-in-out;
    -ms-transition: all 0.25s ease-in-out;
    transition: all 0.25s ease-in-out;
  }

  .wsmobileheader>.smllogo {
    display: block;
    /* width: 180px; */
    margin: 0 auto;
    float: none;
    padding-left: 0px;
  }

  .mobiletext {
    display: inline-block;
  }

  /*Mobile Search Bar*/
  .wsmobileheader .wssearch {
    background-color: transparent;
    z-index: 1000;
    position: absolute;
    top: 0px;
    right: 0px;
    padding: 18px 22px 18px 0;
    cursor: pointer;
  }

  .wsmobileheader .wssearch i {
    font-size: 18px;
    color: #9196a0;
  }

  .wsmobileheader .wssearchform {
    display: none;
    position: absolute;
    width: 100%;
    height: 50px;
    line-height: 44px;
    top: 0px;
    left: 0;
    padding: 13px 15px;
    cursor: default;
  }

  .wsmobileheader .wssearch.wsopensearch {
    width: 100%;
    background-color: theme-color("primary");
  }

  .wsmobileheader .wssearch.wsopensearch .wssearchform {
    display: block;
  }

  .wsmobileheader .wssearchform div {
    width: calc((100% - 10px) - 45px);
    display: block;
    position: relative;
  }

  .wsmobileheader .wssearchform div:before {
    content: "\f002";
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    left: 12px;
    position: absolute;
    top: 0;
    color: #777777;
    height: 34px;
    line-height: 36px;
    font-size: 15px
  }

  .wsmobileheader .wssearchform input {
    width: 100%;
    height: 34px;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    border-radius: 2px;
    border: solid 0px;
    text-indent: 33px;
    margin: 0px;
    line-height: 18px;
    padding: 0px;
    display: block;
  }

  .wsmobileheader .wssearch.wsopensearch {
    left: 0px;
    right: auto;
    width: 100%;
    height: 60px;
  }

  .wsmobileheader .wssearch i.wsclosesearch {
    display: none;
  }

  .wsmobileheader .wssearch.wsopensearch i.wsclosesearch {
    display: inline-block;
    float: right;
    font-size: 23px;
  }

  .wsmobileheader .wssearch.wsopensearch i.wsopensearch {
    display: none;
  }

  /* Mobile Toggle Menu icon (X ICON) */
  .wsanimated-arrow {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 102;
    -webkit-transition: all 0.4s ease-in-out;
    -moz-transition: all 0.4s ease-in-out;
    -o-transition: all 0.4s ease-in-out;
    -ms-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;
  }

  .wsanimated-arrow {
    cursor: pointer;
    padding: 16px 35px 16px 0px;
    margin: 12px 0 0 15px;
  }

  .wsanimated-arrow span,
  .wsanimated-arrow span:before,
  .wsanimated-arrow span:after {
    cursor: pointer;
    height: 2px;
    width: 17px;
    background: #9196a0;
    position: absolute;
    display: block;
    content: '';
  }

  .wsanimated-arrow span:before {
    top: -7px;
    width: 23px;
  }

  .wsanimated-arrow span:after {
    bottom: -7px;
    width: 17px;
  }

  .wsanimated-arrow span,
  .wsanimated-arrow span:before,
  .wsanimated-arrow span:after {
    transition: all 500ms ease-in-out;
  }

  .wsactive .wsanimated-arrow span:after {
    width: 23px;
  }

  .wsactive .wsanimated-arrow span {
    background-color: transparent;
  }

  .wsactive .wsanimated-arrow span:before,
  .wsactive .wsanimated-arrow.active span:after {
    top: 2px;
  }

  .wsactive .wsanimated-arrow span:before {
    transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    bottom: 0px;
  }

  .wsactive .wsanimated-arrow span:after {
    transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    bottom: -2px;
  }

  /*Mobile Scanner Button*/

  .wsmobileheader .wsscanner {
    background-color: transparent;
    z-index: 1000;
    position: absolute;
    top: 0px;
    right: 0px;
    padding: 2px 0 18px 0;
    cursor: pointer;
  }

  /*Mobile Basket Button*/

  .wsmobileheader .wsbasket {
    background-color: transparent;
    z-index: 1000;
    position: absolute;
    top: 0px;
    right: 25px;
    padding: 2px 16px 18px 0;
    cursor: pointer;
  }

  .wsmobileheader .wsbasket i {
    font-size: 18px;
    color: #9196a0;
  }

  div.wsbasketicon {
    float: right;
  }

  div.wsbasketicon>a {
    display: block;
    color: #828181;
    padding: 0 26px 0 22px;
    line-height: 57px;
    text-decoration: none;
    position: relative;
  }

  div.wsbasketicon .hidetxt {
    display: none;
  }

  div.wsbasketicon a {
    padding: 0 17px 0 13px;
    text-align: center;
    /* border-left: 1px solid rgba(0, 0, 0, 0.1);
    border-right: 1px solid rgba(0, 0, 0, 0.1);*/
  }

  div.wsbasketicon a i {
    font-size: 16px;
  }

  div.wsbasketicon em.roundpoint {
    position: absolute;
    top: 14px;
    right: 11px;
    width: 17px;
    height: 17px;
    background-color: theme-color("primary");
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    font-size: 10px;
    text-align: center;
    font-style: normal;
    line-height: 16px;
    color: #fff;
  }

  div.wsbasketicon i {
    display: inline-block;
    font-size: 13px;
    line-height: inherit;
    margin-right: 2px;
    color: #7B7B7B;
  }

  /* ================== Mobile Overlay/Drawer CSS ================== */
  .wsmenu>.overlapblackbg {
    right: 0;
    width: calc(100% - 260px);
    height: 100vh;
    min-height: 100%;
    position: fixed;
    top: 0;
    opacity: 0;
    visibility: hidden;
    background-color: rgba(0, 0, 0, 0.45);
    cursor: pointer;
  }

  .wsactive .wsmenu>.overlapblackbg {
    opacity: 1;
    visibility: visible;
    -webkit-transition: opacity 1.5s ease-in-out;
    -moz-transition: opacity 1.5s ease-in-out;
    -ms-transition: opacity 1.5s ease-in-out;
    -o-transition: opacity 1.5s ease-in-out;
  }

  .wsmenucontainer {
    -webkit-transition: all 0.25s ease-in-out;
    -moz-transition: all 0.25s ease-in-out;
    -o-transition: all 0.25s ease-in-out;
    -ms-transition: all 0.25s ease-in-out;
    transition: all 0.25s ease-in-out;
  }

  .wsactive .wsmenucontainer {
    margin-left: 260px;
    -webkit-transition: all 0.25s ease-in-out;
    -moz-transition: all 0.25s ease-in-out;
    -o-transition: all 0.25s ease-in-out;
    -ms-transition: all 0.25s ease-in-out;
    transition: all 0.25s ease-in-out;
  }

  .wsactive .wsmenu {
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    visibility: visible;
    z-index: 1000;
    top: 0;
  }

  .wsactive .wsmenu>.wsmenu-list {
    -webkit-transition: all 0.25s ease-in-out;
    -moz-transition: all 0.25s ease-in-out;
    -o-transition: all 0.25s ease-in-out;
    -ms-transition: all 0.25s ease-in-out;
    transition: all 0.25s ease-in-out;
    margin-left: 0;
  }

  /* ================== Mobile Sub Menu Expander Arrows  ================== */
  .wsmenu>.wsmenu-list>li>.wsmenu-click {
    border-left: 1px solid;
    cursor: pointer;
    display: block;
    height: 60px;
    position: absolute;
    right: 0;
    top: 0;
    width: 49px;
    z-index: 10;
  }

  .wsmenu>.wsmenu-list>li>.wsmenu-click>i {
    display: block;
    height: 8px;
    width: 8px;
    float: right;
    transform: rotate(-225deg);
    margin: 23px 21px 0px 0px;
  }

  .wsmenu>.wsmenu-list>li>.wsmenu-click>i:before {
    content: "";
    width: 100%;
    height: 100%;
    border-width: 1.5px 1.5px 0 0;
    border-style: solid;
    border-color: rgba(0, 0, 0, 0.40);
    transition: 0.2s ease;
    display: block;
    transform-origin: 100% 0;
  }

  .wsmenu>.wsmenu-list>li>.wsmenu-click.ws-activearrow>i {
    transform: rotate(-45deg);
    margin-top: 27px;
  }

  .wsmenu .wsmenu-list>li>.wsshoptabing>.wsshopwp>.wstabitem>li>.wsmenu-click02 {
    border-left: 1px solid #f3f3f3;
    cursor: pointer;
    display: block;
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    width: 49px;
    z-index: 10;
    background-color: #f5f5f5;
  }

  .wsmenu .wsmenu-list>li>.wsshoptabing>.wsshopwp>.wstabitem>li>.wsmenu-click02 i {
    display: block;
    height: 8px;
    width: 8px;
    float: right;
    transform: rotate(-225deg);
    margin: 17px 21px 0px 0px;
  }

  .wsmenu .wsmenu-list>li>.wsshoptabing>.wsshopwp>.wstabitem>li>.wsmenu-click02 i:before {
    content: "";
    width: 100%;
    height: 100%;
    border-width: 1.5px 1.5px 0 0;
    border-style: solid;
    border-color: rgba(0, 0, 0, 0.40);
    transition: 0.2s ease;
    display: block;
    transform-origin: 100% 0;
  }

  .wsmenu .wsmenu-list>li>.wsshoptabing>.wsshopwp>.wstabitem>li>.wsmenu-click02.ws-activearrow02>i {
    transform: rotate(-45deg);
    margin-top: 21px;
  }

  .wsmenu .wsmenu-list>li>.wtsbrandmenu>.wsshoptabingwp>.wstabitem02>li>.wsmenu-click03 {
    border-left: 1px solid #f3f3f3;
    cursor: pointer;
    display: block;
    height: 45px;
    position: absolute;
    right: 0;
    top: 0;
    width: 49px;
    z-index: 10;
    background-color: #f3f3f3;
  }

  .wsmenu .wsmenu-list>li>.wtsbrandmenu>.wsshoptabingwp>.wstabitem02>li>.wsmenu-click03 i {
    display: block;
    height: 8px;
    width: 8px;
    float: right;
    transform: rotate(-225deg);
    margin: 17px 21px 0px 0px;
  }

  .wsmenu .wsmenu-list>li>.wtsbrandmenu>.wsshoptabingwp>.wstabitem02>li>.wsmenu-click03 i:before {
    content: "";
    width: 100%;
    height: 100%;
    border-width: 1.5px 1.5px 0 0;
    border-style: solid;
    border-color: rgba(0, 0, 0, 0.40);
    transition: 0.2s ease;
    display: block;
    transform-origin: 100% 0;
  }

  .wsmenu .wsmenu-list>li>.wtsbrandmenu>.wsshoptabingwp>.wstabitem02>li>.wsmenu-click03.ws-activearrow03>i {
    transform: rotate(-45deg);
    margin-top: 21px;
  }

  /*End Media Query*/
}

/* Extra @Media Query*/
/*@media only screen and (min-width: 992px) and (max-width:1262px) {
    .wsmenu>.wsmenu-list>li.wssearchbar {
      width: 21%;
    }
  
    .smllogo {
      width: 7%;
    }
  
    .wsmenu {
      width: 93%
    }
  }*/


/* Desktop Main Menu Color */

.headerfull {
  background-color: #fff;
}

/*.wsmenu>.wsmenu-list {
    background-color: #fff;
  }*/

.wsmenu>.wsmenu-list>li>.navtext>span {
  color: $text-muted;
}

.wsmenu>.wsmenu-list>li>.navtext>span+span {
  color: theme-color("primary");
}

.wsmenu>.wsmenu-list>li>.navtext>span+span:after {
  color: $text-muted;
}

.wsmenu>.wsmenu-list>li.wsshopmyaccount>a {
  color: $text-muted;
}

.wsmenu>.wsmenu-list>.wsshopmyaccount>a i {
  color: $text-muted;
}

.wsmenu>.wsmenu-list>li.wsflagicon>a {
  color: $text-muted;
}

.wsmenu>.wsmenu-list>.wsflagicon>a i {
  color: $text-muted;
}

.wsmenu>.wsmenu-list>li.wssearchbar>.topmenusearch>input {
  background-color: #eeeeee;
}

.wsmenu>.wsmenu-list>li.wssearchbar>.topmenusearch>.btnstyle {
  background-color: #eeeeee;
}

.wsmenu>.wsmenu-list>li.wssearchbar>.topmenusearch>input:focus {
  color: $body-color;
}

.wsmenu>.wsmenu-list>li>a {
  color: $text-muted;
}

.wsmenu>.wsmenu-list>li>a .wsarrow:after {
  border-top-color: #b3b3b3;
}

.wsmenu>.wsmenu-list>li>a:hover .wsarrow:after {
  border-top-color: $dark;
}

.wsmenu>.wsmenu-list>li>a.active .wsarrow:after {
  border-top-color: $dark;
}

.wsmenu>.wsmenu-list>li:hover>a .wsarrow:after {
  border-top-color: $dark;
}

.wsmenu>.wsmenu-list>li>a>i {
  color: $dark;
}

.wsmenu>.wsmenu-list>li>a.active i {
  color: $dark;
}

.wsmenu>.wsmenu-list>li:hover>a>i {
  color: $dark;
}

.wsmenu>.wsmenu-list>li>a:hover i {
  color: $dark;
}

.wsmenu>.wsmenu-list>li.wssearchbar>.topmenusearch>.btnstyle:hover {
  background-color: #d7d7d7;
}

.wsmenu>.wsmenu-list>li.wssearchbar>.topmenusearch>.btnstyle:hover i {
  color: $dark;
}

.wsmenu>.wsmenu-list>li.wssearchbar>.topmenusearch>input:focus~.btnstyle {
  background-color: #d7d7d7;
}

.wsmenu>.wsmenu-list>li.wssearchbar>.topmenusearch>input:focus~.btnstyle>i {
  color: $dark;
}

.wsmenu>.wsmenu-list>li.wssearchbar>.topmenusearch>input:focus~.btnstyle i {
  color: $dark;
}

.wsmenu>.wsmenu-list>li>a.active {
  background-color: #f5f5f5;
  color: $dark;
}

.wsmenu>.wsmenu-list>li:hover>a {
  background-color: #f5f5f5;
  color: $dark;
}

.wsmenu>.wsmenu-list>li>ul.sub-menu>li:hover>a {
  background-color: #f5f5f5;
  color: #333;
  text-decoration: none;
}

.wsmenu>.wsmenu-list>li>ul.sub-menu>li>ul.sub-menu>li:hover>a {
  background-color: #f5f5f5;
  color: #333;
}

.wsmenu>.wsmenu-list>li>.wsshoptabing>.wsshopwp>.wstabitem>li.wsshoplink-active>a {
  background-color: #f5f5f5;
  color: theme-color("primary");
  border-left: 3px solid theme-color("primary");
}

/* Mobile Header Color */

.wsmobileheader {
  background-color: #fff;
}

.wsactive .wsmobileheader {
  border-left: solid 1px #e0e0e0;
}

.wsmenu>.wsmenu-list>li>.wsmenu-click {
  border-color: rgba(0, 0, 0, 0.1);
}

.wsmenu>.wsmenu-list>li>.wsmenu-click>i {
  color: rgba(0, 0, 0, 0.25);
}

.wsmenu>.wsmenu-list>li>a {
  border-bottom-color: rgba(0, 0, 0, 0.13);
}


/* clears the ‘X’ from Internet Explorer */
input[type=search]::-ms-clear {
  display: none;
  width: 0;
  height: 0;
}

input[type=search]::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}

/* clears the ‘X’ from Chrome */
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  display: none;
}



/* ============================= */
/* == COLLAPSE ================= */
/* ============================= */


.wsmenu-list>li>.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease !important;


  -o-transition: -o-transform height 0.65s ease !important;
  -ms-transition: -ms-transform height 0.65s ease !important;
  -moz-transition: -moz-transform height 0.65s ease !important;
  -webkit-transition: -webkit-transform height 0.65s ease !important;
}