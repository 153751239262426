/* ================================= */
/* ========VIDEO CALLING CSS======== */
/* ================================= */
.pvs-call-local {
  transition: width 400ms ease-in-out 0s;
  position: absolute;
  bottom: 15vh;
  right: 16px;
  width: 235px;
  height: 132px;
  z-index: 999;
}

.pvs-call-local.productless {
  top: 20vh;
}

.pvs-call-3way-remote-left {
  transition: width 400ms ease-in-out 0s;
  position: absolute;
  top: 60px;
  left: 0;
  width: 50%;
  height: calc(100vh - 147px);
  z-index: 900;
  overflow: hidden;
}

.pvs-call-3way-remote-right {
  transition: width 400ms ease-in-out 0s;
  position: absolute;
  top: 60px;
  left: 50%;
  width: 50%;
  height: calc(100vh - 147px);
  z-index: 900;
  overflow: hidden;
  border-left: 1px solid #3E4044;
}

.pvs-call-2way-remote {
  transition: width 400ms ease-in-out 0s;
  position: absolute;
  top: 60px;
  left: 0;
  width: 100%;
  height: calc(100vh - 147px);
  z-index: 900;
  overflow: hidden;
}

.pvs-call-local video {
  transition: width 400ms ease-in-out 0s;
  background-color: #515358;
  width: 100%;
  height: 100%;
  border-radius: 6px;
}

.pvs-call-local .loading {
  background-color: #515358;
  color: #fff;
  width: 100%;
  height: 100%;
  border-radius: 6px;
  display: flex;
  margin-bottom: 6px;
}

.pvs-call-3way-remote-left video,
.pvs-call-3way-remote-right video,
.pvs-call-2way-remote video {
  background-color: #202123;
  width: 100%;
  height: 100%;
}

.pvs-call-3way-remote-left .loading,
.pvs-call-3way-remote-right .loading,
.pvs-call-2way-remote .loading {
  background-color: #202123;
  color: #fff;
  width: 100%;
  height: 100%;
  display: flex;
}

.pvs-call-local .inner .name-label {
  position: absolute;
  top: 10px;
  left: 10px;
  color: #fff;
  text-shadow: 1px 1px black;
}

.pvs-call-3way-remote-left .inner .name-label,
.pvs-call-3way-remote-right .inner .name-label,
.pvs-call-2way-remote .inner .name-label {
  position: absolute;
  top: 10px;
  left: 10px;
  color: #fff;
  text-shadow: 1px 1px black;
}

.pvs-call-local .inner .mic-off {
  position: absolute;
  top: 10px;
  right: 10px;
  color: #df0000;
}

.pvs-call-3way-remote-left .inner .mic-off,
.pvs-call-3way-remote-right .inner .mic-off,
.pvs-call-2way-remote .inner .mic-off {
  position: absolute;
  top: 10px;
  right: 10px;
  color: #df0000;
}

.pvs-call-3way-remote-left .inner .magnifying-glass,
.pvs-call-3way-remote-right .inner .magnifying-glass,
.pvs-call-2way-remote .inner .magnifying-glass {
  position: absolute;
  top: 40px;
  right: 20px;
  color: #fff;
  font-size: 40px;
}

.pvs-call-3way-remote-left .inner .magnifying-glass.productless,
.pvs-call-3way-remote-right .inner .magnifying-glass.productless,
.pvs-call-2way-remote .inner .magnifying-glass.productless {
  top: 20px;
}

.vid-controls button {
  width: 50px;
}

.pvs-call .fixed-bottom {
  z-index: 999;
}

/* ========CHANGE VIEW TO HORIZONAL FOR TABLET ======== */
@media only screen and (max-width: 768px) {
  .pvs-call-local {
    position: absolute;
    bottom: 120px;
    right: 16px;
    width: 235px;
    height: 132px;
    z-index: 999;
  }

  .pvs-call-3way-remote-left {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 50%;
    z-index: 900;
  }

  .pvs-call-3way-remote-right {
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    height: 50%;
    z-index: 900;
    border-left: none;
    border-top: 1px solid #3E4044;
  }

  .pvs-call-2way-remote {
    position: absolute;
    top: 0vh;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 900;
  }

  .pvs-call-local video {
    background-color: #515358;
    width: 100%;
    height: 100%;
    border-radius: 6px;
  }

  .pvs-call-3way-remote-left video,
  .pvs-call-3way-remote-right video,
  .pvs-call-2way-remote video {
    background-color: #202123;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .pvs-call-local .loading {
    background-color: #515358;
    width: 100%;
    height: 100%;
    display: flex;
  }

  .pvs-call-3way-remote-left .loading,
  .pvs-call-3way-remote-right .loading,
  .pvs-call-2way-remote .loading {
    background-color: #202123;
    width: 100%;
    height: 100%;
    display: flex;
  }

  .pvs-call-3way-remote-right .inner .name-label {
    position: absolute;
    top: 12px;
    left: 10px;
    color: #fff;
    text-shadow: 1px 1px black;
  }

  .pvs-call-3way-remote-right .inner .mic-off {
    position: absolute;
    top: 12px;
    right: 10px;
    color: #df0000;
  }

  .pvs-call-3way-remote-left .inner .magnifying-glass {
    position: absolute;
    top: 100px;
    right: 40px;
    color: #fff;
    font-size: 30px;
  }

  .pvs-call-3way-remote-left .inner .magnifying-glass.productless {
    top: 80px;
  }

  .pvs-call-3way-remote-right .inner .magnifying-glass {
    position: absolute;
    top: 10px;
    right: 40px;
    color: #fff;
    font-size: 30px;
  }
}

/* ======== END CHANGE VIEW TO HORIZONAL FOR TABLET ======== */
/* adjustments for TABLET/MOBILE  */
@media only screen and (max-width: 768px) {
  .pvs-call-local {
    width: 167px;
    height: 94px;
  }

  .pvs-call-local .loading svg {
    width: 40px;
    height: 40px;
  }

  .pvs-call-2way-remote .inner .name-label,
  .pvs-call-3way-remote-left .inner .name-label {
    top: 70px;
  }

  div.name-label span.hide-label {
    display: none;
  }

  .pvs-call-2way-remote .inner .magnifying-glass {
    top: 100px;
  }

  .pvs-call-2way-remote .inner .magnifying-glass.productless {
    top: 80px;
  }

  .pvs-call-3way-remote-right .inner .magnifying-glass {
    top: 30px;
  }

  .pvs-call-3way-remote-right .inner .magnifying-glass.productless {
    top: 10px;
  }

  .pvs-call-3way-remote-left .inner .mic-off,
  .pvs-call-2way-remote .inner .mic-off {
    top: 70px;
  }
}

@media only screen and (max-width: 480px) {
  .pvs-call-local {
    width: 135px;
    height: 76px;
  }

  .pvs-call-local .loading svg {
    width: 25px;
    height: 25px;
  }
}

.pvs-call-not-found,
.pvs-call-end {
  display: flex;
  background-color: #202123;
  flex-direction: column;
  position: fixed;
  inset: 0;
  top: 60px;
  overflow: auto;
}

.pvs-call-reference {
  font-size: 11px;
  color: #fff;
  margin-bottom: -20px;
}

.pvs-call-duration {
  color: #fff;
  text-align: center;
  margin-bottom: 0.25rem;
}

/* ======== CHANGE VIEW WHEN VL SHOPPIN FOOTER COMES IN ======== */
@media only screen and (max-width: 991px) {
  .pvs-call-local.with-vl-shopping-footer {
    bottom: 155px;
  }

  .video-controls-wrap.with-vl-shopping-footer {
    bottom: 88px;
  }
}

.cc-modal p {
  margin: 0;
}

.speaker-a {
  color: #87CEEB !important;
}

.speaker-i {
  color: #32CD32 !important;
}

.speaker-c {
  color: #FFFF00 !important;
}

.speaker-f {
  color: #32CD32 !important;
}

.video-feed {
  width: 100% !important;
  height: 100% !important;
  margin-bottom: 6px;
}

/* ======== END VIDEO CALL CSS ======== */
/* ================================= */
/* ===========CHAT BOX CSS========== */
/* ================================= */
.pvs-chat-box {
  position: absolute;
  top: 60px;
  left: 0;
  width: 34%;
  height: calc(100vh - 147px);
  z-index: 900;
  background-color: #efefef;
  transition: width 400ms ease-in-out 0s;
  z-index: 1071;
}

.pvs-chat-box .close {
  outline: none;
}

.pvs-call-3way-remote-left.chat-active {
  transition: width 400ms ease-in-out 0s;
  left: 34%;
  width: 33%;
}

.pvs-call-3way-remote-right.chat-active {
  transition: width 400ms ease-in-out 0s;
  left: 67%;
  width: 33%;
}

.pvs-call-2way-remote.chat-active {
  transition: width 400ms ease-in-out 0s;
  left: 34%;
  width: 66%;
}

@media only screen and (max-width: 991px) {
  .pvs-call-3way-remote-left.chat-active .inner .name-label {
    top: 70px;
  }

  .pvs-call-3way-remote-left.chat-active {
    transition: width 400ms ease-in-out 0s;
    left: 34%;
    width: 66%;
    top: 0;
    height: 50%;
  }

  .pvs-call-3way-remote-right.chat-active {
    transition: width 400ms ease-in-out 0s;
    left: 34%;
    width: 66%;
    top: 50%;
    height: 50%;
    border-top: 1px solid #3E4044;
  }

  .pvs-chat-box {
    height: calc(100% - 60px);
  }

  .chat-contents {
    height: calc(100vh - 296px) !important;
  }
}

.chat-contents {
  overflow-y: scroll;
  height: calc(100vh - 354px);
}

.my-chat-entry {
  margin-top: 16px;
  display: flex;
  justify-content: flex-end;
  max-width: 100%;
}

.my-chat-entry-inner {
  padding: 4px 8px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.my-chat-entry .user-label,
.other-chat-entry .user-label {
  margin-bottom: 6px;
  color: #767676;
}

.chat-caption {
  margin-bottom: 0;
  color: #767676;
}

.other-chat-entry {
  margin-top: 16px;
  display: flex;
  justify-content: flex-start;
  max-width: 100%;
}

.other-chat-entry-inner {
  padding: 4px 8px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.chat-entry-line {
  display: inline-block;
  white-space: pre-wrap;
  word-break: break-word;
}

p.chat-entry-line {
  margin-bottom: 0;
}

.chat-caption {
  font-style: italic;
  font-size: 0.85rem;
}

.chat-input-wrap {
  height: 95px;
  display: flex;
  border-top: 1px solid rgba(112, 112, 112, 0.2);
  align-items: center;
}

.chat-input-inner {
  color: #212529;
  cursor: text;
  display: inline-flex;
  position: relative;
  font-size: 1rem;
  box-sizing: border-box;
  align-items: center;
  line-height: 1.1876em;
  padding: 18px 14px 4px 14px;
  width: 100%;
  border-radius: 4px;
}

.chat-textarea {
  width: 100%;
  margin: 0;
  display: block;
  min-width: 0;
  box-sizing: content-box;
  height: 50px !important;
  padding: 0.45rem 1rem;
  resize: none;
}

.chat-controls-outer {
  display: flex;
  max-height: 2em;
  align-items: center;
  white-space: nowrap;
}

.chat-controls-inner {
  display: flex;
}

.btn-file {
  position: relative;
  overflow: hidden;
}

.btn-file input[type=file] {
  position: absolute;
  top: 0;
  right: 0;
  min-width: 100%;
  min-height: 100%;
  text-align: right;
  filter: alpha(opacity=0);
  opacity: 0;
  outline: none;
  display: block;
  cursor: pointer;
}

.chat-info-msg {
  padding-left: 20px;
}

a.chat-file {
  display: inline-block;
  margin: 10px 0 4px 0;
  text-decoration: underline;
}

a.chat-file:hover {
  text-decoration: none;
}

a.chat-file:before {
  content: "\f15b";
  font-family: "Font Awesome 5 Free";
  font-weight: normal;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  display: inline-block;
  width: 20px;
}

a.chat-file.corrupted:before {
  content: "\f06a";
  font-weight: 900;
}

a.chat-file.corrupted {
  pointer-events: none;
  cursor: default;
  color: #777;
  text-decoration: none;
}

.new-chat-notification {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 0.75rem;
  position: absolute;
  top: 4px;
  right: 7px;
  width: 16px;
  height: 16px;
  color: #fff;
  background-color: red;
  border-radius: 50%;
}

/* adjustments for TABLET/MOBILE  */
@media only screen and (max-width: 768px) {
  .pvs-chat-box {
    /*position: absolute;
    height: 100vh;*/
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    outline: 0;
  }

  .chat-contents {
    height: calc(100vh - 290px) !important;
  }
}

/* ============================================== /
/ ======CHAT BOX NO VIDEO AKA CHAT ONLY CSS===== /
/ ============================================== */

.pvs-chat-box.no-video {
  width: 50%;
  height: calc(100vh - 60px);
  z-index: auto;
}

.pvs-chat-box.no-video .chat-contents {
  height: calc(100vh - 296px) !important;
}

@media only screen and (max-width: 991px) {
  .pvs-chat-box.no-video {
    width: 66%;
  }
}

@media only screen and (max-width: 768px) {
  .pvs-chat-box.no-video {
    position: fixed;
    top: 60px;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

/* ======== END CHAT BOX CSS ======== */

/* =================================== */
/* ====MINIMIZE LOCAL VIDEO PANEL===== */
/* =================================== */
#minimize-local-panel-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-size: 24px;
  color: #FFFFFF;
  border: 0;
  outline: none;
}

.minimized-local-panel {
  width: 0px;
  position: fixed;
  right: 16px;
}

.minimized-local-panel.productless {
  bottom: 10px;
}

.minimized-local-panel .minimize-local {
  position: absolute;
  right: 0;
}

.minimized-local-panel .minimize-local.remote {
  position: absolute;
  left: 0;
}

.minimize-local:focus {
  outline: none !important;
  box-shadow: none !important;
}

.minimize-local {
  display: flex;
  align-items: center;
}

.minimize-local i {
  display: inline-block;
  margin-right: 8px;
}

.minimize-local .mic-off {
  position: static !important;
  display: flex;
}

/* ================================= */
/* ====CLOSED CAPTIONS (CC) CSS===== */
/* ================================= */
.cc-modal {
  position: absolute;
  top: 4px;
  border-radius: 0.6rem !important;
  left: 0;
  z-index: auto;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
  display: none;
  pointer-events: none;
}

.cc-modal .cc-modal-dialog {
  max-width: 100%;
  position: relative;
  width: auto;
  padding: 2rem;
  margin: 4.75rem auto;
}

.cc-modal .cc-modal-dialog.broadcast,
.cc-modal .cc-modal-dialog.broadcast-fs-m,
.cc-modal .cc-modal-dialog.broadcast-fs-l,
.cc-modal .cc-modal-dialog.broadcast-fs-xl {
  max-width: 100%;
  position: relative !important;
  width: auto;
  padding: 2rem;
  margin: 0;
}

.cc-modal .cc-modal-dialog.productless {
  position: fixed;
  bottom: 80px;
  left: 0;
  right: 0;
  margin: auto;
}

.cc-modal-content-dark {
  background: rgba(0, 0, 0, 0.7);
  border-radius: 0.6rem !important;
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  border: 1px solid rgba(0, 0, 0, 0.2);
  outline: 0;
}

.cc-modal-content-dark p,
.cc-modal-body {
  font-size: 1.4rem;
  color: #fdfdfd;
  -webkit-text-stroke: 0.007em black;
  text-shadow: 0.03em 0.03em 0 black;
}

.cc-modal .cc-modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem;
}

.broadcast .cc-modal-content-dark p {
  font-size: 4rem;
}

.broadcast .cc-entry {
  overflow-y: hidden;
  max-height: 270px !important;
}

.broadcast-fs-m .cc-entry,
.broadcast-fs-l .cc-entry,
.broadcast-fs-xl .cc-entry {
  // overflow-y: hidden;
  max-height: 470px !important;
}

.broadcast-fs-m .cc-modal-content-dark p {
  font-size: 1.5rem;
}

.broadcast-fs-l .cc-modal-content-dark p {
  font-size: 2rem;
}

.broadcast-fs-xl .cc-modal-content-dark p {
  font-size: 3rem;
}

@media only screen and (max-width: 991px) {
  .pvs-call-3way-remote-left.chat-active .cc-modal {
    top: 64px;
  }
}

@media only screen and (max-width: 768px) {
  .pvs-call-3way-remote-left .cc-modal {
    top: 44px;
  }

  .pvs-call-3way-remote-right .cc-modal {
    top: -10px;
  }
}

@media only screen and (max-width: 480px) {
  .cc-modal-content-dark p {
    font-size: 1.1rem;
  }

  .cc-entry {
    max-height: 80px !important;
  }

  .broadcast .cc-modal-content-dark p {
    font-size: 2rem;
  }

  .broadcast .cc-entry {
    max-height: 500px !important;
  }

  .broadcast-fs-m .cc-entry,
  .broadcast-fs-l .cc-entry,
  .broadcast-fs-xl .cc-entry {
    max-height: 850px !important;
  }
}

@media only screen and (max-width: 768px) {
  .pvs-call-2way-remote .cc-modal {
    top: 44px;
  }
}

.fa-closed-captioning-slash {
  -webkit-appearance: none;
  height: 18px;
  width: 18px;
  margin-bottom: -4px;
  background-repeat: no-repeat;
  background-image: url('data:image/svg+xml;utf8,<svg style="color: white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M6.83,4H19c1.1,0,2,0.9,2,2v12c0,0.05-0.01,0.1-0.02,0.16l-3.38-3.38C17.84,14.59,18,14.32,18,14v-1h-1.5v0.5h-0.17 l-1.83-1.83V10.5h2V11H18v-1c0-0.55-0.45-1-1-1h-3c-0.55,0-1,0.45-1,1v0.17L6.83,4z M19.78,22.61L17.17,20H5c-1.11,0-2-0.9-2-2V6 c0-0.05,0.02-0.1,0.02-0.15L1.39,4.22l1.41-1.41l18.38,18.38L19.78,22.61z M11,13.83L10.17,13H9.5v0.5h-2v-3h0.17L6.4,9.22 C6.16,9.41,6,9.68,6,10v4c0,0.55,0.45,1,1,1h3c0.55,0,1-0.45,1-1V13.83z" fill="white"></path></svg>');
}

/* Adjustments to put the small video screen at the top, and the subtitles at the bottom */
.cc-modal .cc-modal-dialog.productless {
  position: absolute !important;
  bottom: 0px !important;
  width: 100% !important;
}

@media only screen and (max-width: 768px) {
  .cc-modal .cc-modal-dialog.productless {
    margin: 0 auto !important;
    bottom: 48px !important;
  }

  .pvs-call-2way-remote .cc-modal.productless {
    top: -22px !important;
  }

  .pvs-call-3way-remote-right .cc-modal.productless {
    top: -22px !important;
  }
}

.cc-entry {
  overflow-y: scroll;
  max-height: 130px;
}

.cc-entry::-webkit-scrollbar {
  width: 0.5em;
}

.cc-entry::-webkit-scrollbar-track {
  background-color: transparent;
}

.cc-entry::-webkit-scrollbar-thumb {
  background-color: transparent;
}

/* == END CLOSED CAPTIONS (CC) CSS = */